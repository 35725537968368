import { render, staticRenderFns } from "@/components/forms/XrInlineTextareafield.pug?vue&type=template&id=484c505f&lang=pug&"
import script from "./XrInlineTextareafield.vue?vue&type=script&lang=ts&"
export * from "./XrInlineTextareafield.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
