




import Vue from 'vue';

export default Vue.extend({
  name: 'xr-inline-textfield',
  inheritAttrs: false,
  props: {
    value: String,
    isEditEnabled: Boolean,
  },
});
